<template>
  <v-container
    fluid
    class="pa-0 white"
  >
    <div
      v-show="!mobileDevice"
      class="hidden-xs-and-up"
    >
      <div
        class="section1 pb-16 small"
      >
        <sequential-entrance from-top>
          <v-row class="pt-16 ml-16">
            <v-col class="pt-16 mt-16">
              <div class="title-text-small">
                Get a <span class="primary--text">travel</span> insurance <br> quote and apply online
              </div>
            </v-col>
          </v-row>
        </sequential-entrance>
        <sequential-entrance from-bottom>
          <v-row
            class="ml-12"
          >
            <v-col
              cols="6"
              class="d-inline-flex align-center"
            >
              <v-img
                src="/img/travel-btn.png"
                class="travel-qt"
              ></v-img>
              <v-btn
                class="primary white--text font-weight-bold"
                to="/travelinfocanada"
                large
              >
                <span class="px-4">Travel Quote</span>
              </v-btn>
              <v-btn
                class="white learn font-weight-bold"
                to="/travelcalearnmore"
                large
              >
                <span class="secondary--text">Learn More</span>
              </v-btn>
            </v-col>
          </v-row>
        </sequential-entrance>
      </div>
      <div class="section2 pb-16">
        <v-row class="pt-8 pl-16 pr-16">
          <v-col
            cols="12"
            sm="6"
            md="4"
            lg="4"
          >
            <v-card
              elevation="4"
              min-height="400"
            >
              <v-row class="d-flex justify-center">
                <v-icon
                  size="68"
                  color="primary"
                  class="mt-8 mb-8"
                >
                  mdi-medical-bag
                </v-icon>
              </v-row>
              <v-card-title class="d-flex justify-center text-h3">
                Emergency Medical
              </v-card-title>
              <v-card-text class="text-h4 ma-2 mt-8">
                No one ever plans to need medical care while away, but if you do experience misadventures on your trip, Emergency Medical Insurance covers hospital and doctors’ fees, prescriptions, emergency transportation and more.
              </v-card-text>
            </v-card>
          </v-col>
          <v-col
            cols="12"
            sm="6"
            md="4"
            lg="4"
          >
            <v-card
              elevation="4"
              min-height="400"
            >
              <v-row class="d-flex justify-center">
                <v-icon
                  size="68"
                  color="primary"
                  class="mt-8 mb-8"
                >
                  mdi-close-circle
                </v-icon>
              </v-row>
              <v-card-title class="d-flex justify-center text-h3">
                Trip Cancellation & Trip Interruption
              </v-card-title>
              <v-card-text class="text-body-1 ma-2 mt-8">
                Life is full of surprises and so is travel! You can’t predict a natural disaster, job loss or the death of a family member or friend. When an unexpected event prevents you from travelling, Trip Cancellation & Trip Interruption reimburses travel costs if your trip is cancelled before you leave, or disrupted after it has begun.
              </v-card-text>
            </v-card>
          </v-col>
          <v-col
            cols="12"
            sm="6"
            md="4"
            lg="4"
          >
            <v-card
              elevation="4"
              min-height="400"
            >
              <v-row class="d-flex justify-center">
                <v-icon
                  size="68"
                  color="primary"
                  class="mt-8 mb-8"
                >
                  mdi-ferry
                </v-icon>
              </v-row>
              <v-card-title class="d-flex justify-center text-h3">
                Trip Interruption
              </v-card-title>
              <v-card-text class="text-body-1 ma-2 mt-8">
                110% sure you’re not going to cancel your travel plans? What if something happens while you’re already away? Trip Interruption Insurance will reimburse your travel costs if your plans are disrupted after the trip has begun.
              </v-card-text>
            </v-card>
          </v-col>
          <v-col
            cols="12"
            sm="6"
            md="4"
            lg="4"
          >
            <v-card
              elevation="4"
              min-height="400"
            >
              <v-row class="d-flex justify-center">
                <v-icon
                  size="68"
                  color="primary"
                  class="mt-8 mb-8"
                >
                  mdi-account-injury
                </v-icon>
              </v-row>
              <v-card-title class="d-flex justify-center text-h3">
                Accidental Death & Dismemberment
              </v-card-title>
              <v-card-text class="text-body-1 ma-2 mt-8">
                Accidental Death & Dismemberment covers you in the event of your unfortunate death or dismemberment, anywhere outside of your home. It’s available as a stand-alone Single Trip or Multi Trip Annual policy, or as part of an All Inclusive Holiday or Non-Medical Package.
              </v-card-text>
            </v-card>
          </v-col>
          <v-col
            cols="12"
            sm="6"
            md="4"
            lg="4"
          >
            <v-card
              elevation="4"
              min-height="400"
            >
              <v-row class="d-flex justify-center">
                <v-icon
                  size="68"
                  color="primary"
                  class="mt-8 mb-8"
                >
                  mdi-airplane-takeoff
                </v-icon>
              </v-row>
              <v-card-title class="d-flex justify-center text-h3">
                Non-Medical Package
              </v-card-title>
              <v-card-text class="text-body-1 ma-2 mt-8">
                Don’t need Emergency Medical insurance coverage? Our Non-Medical package protects you for missed flights, lost baggage and more!
              </v-card-text>
            </v-card>
          </v-col>
          <v-col
            cols="12"
            sm="6"
            md="4"
            lg="4"
          >
            <v-card
              elevation="4"
              min-height="400"
            >
              <v-row class="d-flex justify-center">
                <v-icon
                  size="68"
                  color="primary"
                  class="mt-8 mb-8"
                >
                  mdi-camera
                </v-icon>
              </v-row>

              <v-card-title class="d-flex justify-center text-h3">
                All Inclusive Holiday Package
              </v-card-title>
              <v-card-text class="text-body-1 ma-2 mt-8">
                Looking for a travel insurance plan that has it all? Our All Inclusive Holiday Package is the one for you. You’ll be protected for medical emergencies while away, missed flights, lost or damaged baggage and more.
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row class="pt-8 pl-16 pr-16 ">
          <v-col
            cols="12"
            class="d-flex justify-center"
          >
            <div
              class="pa-4"
            >
              <span class="product-text">Optional Coverages</span> <br>
            </div>
          </v-col>
          <v-col
            cols="12"
            sm="6"
            md="6"
            lg="3"
          >
            <v-card
              elevation="4"
              min-height="400"
            >
              <v-row class="d-flex justify-center">
                <v-icon
                  size="68"
                  color="primary"
                  class="mt-8 mb-8"
                >
                  mdi-weight-lifter
                </v-icon>
              </v-row>
              <v-card-title class="d-flex justify-center text-h3">
                Sports & Activities
              </v-card-title>
              <v-card-text class="text-body-1 ma-2">
                We’re helping travellers have better travel experiences; and knowing they’re covered for the activities they love is an important part of that. That’s what makes our travel insurance unique!
              </v-card-text>
            </v-card>
          </v-col>
          <v-col
            cols="12"
            sm="6"
            md="6"
            lg="3"
          >
            <v-card
              elevation="4"
              min-height="400"
            >
              <v-row class="d-flex justify-center">
                <v-icon
                  size="68"
                  color="primary"
                  class="mt-8 mb-8"
                >
                  mdi-airplane-alert
                </v-icon>
              </v-row>
              <v-card-title class="d-flex justify-center text-h3">
                Cancel for Any Reason
              </v-card-title>
              <v-card-text class="text-body-1 ma-2">
                We’re helping travellers have better travel experiences; and knowing they’re covered for the activities they love is an important part of that. That’s what makes our travel insurance unique!
              </v-card-text>
            </v-card>
          </v-col>
          <v-col
            cols="12"
            sm="6"
            md="6"
            lg="3"
          >
            <v-card
              elevation="4"
              min-height="400"
            >
              <v-row class="d-flex justify-center">
                <v-icon
                  size="68"
                  color="primary"
                  class="mt-8 mb-8"
                >
                  mdi-bag-suitcase
                </v-icon>
              </v-row>
              <v-card-title class="d-flex justify-center text-h3">
                Baggage Insurance
              </v-card-title>
              <v-card-text class="text-body-1 ma-2">
                Avoid getting caught with only the clothes on your back! No one wants to wear that gift shop shirt for a week until the bags arrive. With Baggage Insurance, you will be able to get the essential things you need, while on your trip!
              </v-card-text>
            </v-card>
          </v-col>
          <v-col
            cols="12"
            sm="6"
            md="6"
            lg="3"
          >
            <v-card
              elevation="4"
              min-height="400"
            >
              <v-row class="d-flex justify-center">
                <v-icon
                  size="68"
                  color="primary"
                  class="mt-8 mb-8"
                >
                  mdi-car-info
                </v-icon>
              </v-row>
              <v-card-title class="d-flex justify-center text-h3">
                Rental Car Protection
              </v-card-title>
              <v-card-text class="text-body-1 ma-2">
                You want your rental car to be covered for damage or loss no matter where you’re headed. Rental Car Protection ensures a smooth road ahead.
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </div>
    <!------------------------------------------TABLET------------------------------------------------------>
    <!------------------------------------------MOBILE------------------------------------------------------>
    <div v-show="mobileDevice">
      <div
        class="section1-mobile pb-16"
      >
        <sequential-entrance
          from-top
        >
          <v-row class="d-flex justify-center">
            <v-col>
              <div class="title-text-mobile mt-n4">
                Get a <span class="primary--text">travel</span> insurance <br> quote and apply online
              </div>
            </v-col>
          </v-row>
        </sequential-entrance>
        <sequential-entrance from-bottom>
          <v-row class="d-flex justify-center">
            <v-col
              cols="9"
              class="d-flex align-center justify-center mt-2 ml-4"
            >
              <v-btn
                class="primary white--text font-weight-bold"
                to="/travelinfocanada"
                block
                large
              >
                <span class="travel-quote-mobile">Travel Quote</span>
              </v-btn>
            </v-col>
            <v-col
              cols="9"
              class="mt-0 pt-0"
            >
              <v-btn
                class="white learn font-weight-bold"
                to="/travelcalearnmore"
                block
                large
              >
                <span class="learn-more-mobile">Learn More</span>
              </v-btn>
            </v-col>
          </v-row>
          <v-row class="d-flex justify-center">
          </v-row>
        </sequential-entrance>
      </div>
      <div class="section2 pb-16 pt-16">
        <v-row class="pt-4 d-flex-justify-center">
          <v-col
            class="d-flex justify-center"
          >
            <div class="text-center">
              <span class="product-text-mobile">Our Products</span>
              <br>
              <span class="coverage-text-mobile">Find insurance coverage you need</span>
            </div>
          </v-col>
        </v-row>
        <v-row class="d-flex justify-center">
          <v-col cols="10">
            <div class="product-subtext-mobile">
              Blanket is partnered with some of the leading providers in the insurance world and strives to give you the best coverage and customer experience.
            </div>
          </v-col>
        </v-row>
        <v-row
          class="d-flex justify-center"
        >
          <v-col
            cols="12"
          >
            <v-slide-group
              multiple
              show-arrows
            >
              <v-card
                v-for="(item, index) in productLinks"
                :key="index"
                flat
                @click="goTo(item.route)"
              >
                <v-img
                  :src="item.img"
                  height="140"
                  width="140"
                />
              </v-card>
            </v-slide-group>
          </v-col>
        </v-row>
      </div>
    </div>
  </v-container>
</template>

<script>
  export default {
    name: 'Dashboard',

    data () {
      return {
        productLinks: [
          { title: 'Auto', route: '/auto', img: '/img/product-chips/car.png' },
          { title: 'Homeowners', route: '/homeowners', img: '/img/product-chips/home.png' },
          { title: 'Pet', route: '/pet', img: '/img/product-chips/pet.png' },
          { title: 'Termmlife', route: '/termlifehome', img: '/img/product-chips/termlife.png' },
          { title: 'Business', route: '/business', img: '/img/product-chips/business.png' },
          { title: 'Condo', route: '/condo', img: '/img/product-chips/condo.png' },
          { title: 'Landlord', route: '/landlord', img: '/img/product-chips/landlord.png' },
          { title: 'Renters', route: '/renters', img: '/img/product-chips/renters.png' },
        ],
        tabs: 0,
        list: {
          0: false,
          1: false,
          2: false,
        },
      }
    },
    computed: {
      mobileDevice () {
        return this.$vuetify.breakpoint.xsOnly
      },
      tabletDevice () {
        return this.$vuetify.breakpoint.smOnly
      },
    },
    created () {
      this.$gtag.pageview('Travel USA')
      this.currentuser = this.$store.getters.getuser
    },

    methods: {
      goTo (route) {
        this.$router.push({ path: route })
      },
      navigateTo (product) {
        this.$router.push(product)
      },
      complete (index) {
        this.list[index] = !this.list[index]
      },
      sendevent () {
        this.$gtag.event('blanket', { method: 'Dashboard', campaign: 'none' })
        return 'completed'
      },
    },
  }
</script>
<style scoped>
.product-subtext {
    font-family: 'Roboto';
font-style: normal;
font-weight: 400;
font-size: 24px;
line-height: 132.19%;
/* or 32px */

text-align: center;

/* Gray/600 */

color: #757575;
  }
/*------------------------------MOBILE----------------------------------------------------*/
.product-subtext-mobile {
  font-family: 'Roboto';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 132.19%;
/* or 19px */

text-align: center;

/* Gray/600 */

color: #757575;
}
.product-text-mobile {
font-family: Roboto;
font-size: 20px;
font-weight: 300;
line-height: 23px;
letter-spacing: -0.005em;
text-align: center;
color: #757575;
}
.coverage-text-mobile {
font-family: Roboto;
font-size: 32px;
font-weight: 400;
line-height: 38px;
letter-spacing: -0.005em;
text-align: center;
color: #757575;
}
.learn-more-mobile {
  font-family: 'Roboto';
font-style: normal;
font-weight: 700;
font-size: 16px;
line-height: 19px;
letter-spacing: 0.00892857em;
text-transform: uppercase;

/* Secondary */

color: #005F6C;
}
.travel-quote-mobile {
  font-family: 'Roboto';
font-style: normal;
font-weight: 700;
font-size: 16px;
line-height: 19px;
letter-spacing: 0.00892857em;
text-transform: uppercase;
color: #FFFFFF;
}
.title-text-mobile {
  font-family: 'Roboto';
font-style: normal;
font-weight: 400;
font-size: 32px;
line-height: 125.19%;
/* or 40px */

text-align: center;
letter-spacing: -0.04em;

/* Gray/700 */

color: #616161;
  }
  .section1-mobile{
  position: relative;
  height: 85vh;
  top: 50px;
  background: linear-gradient(to top, rgba(255,255,255,0) 70%, rgba(255,255,255,1) 100%),url( '/img/travel-usa-background-mobile.jpg') no-repeat center center;
  background-size: cover;
  background-color: white;
  background-position: 75%;
}
/*------------------------------TABLET----------------------------------------------------*/
.product-subtext-tablet {
  font-family: 'Roboto';
font-style: normal;
font-weight: 400;
font-size: 20px;
line-height: 132.19%;
/* or 26px */

text-align: center;

/* Gray/600 */

color: #757575;
}
.product-text-tablet {
  font-family: 'Roboto';
font-style: normal;
font-weight: 300;
font-size: 32px;
line-height: 38px;
letter-spacing: -0.005em;
text-transform: uppercase;

/* Gray/600 */

color: #757575;
}

.coverage-text-tablet {
  font-family: Roboto;
font-size: 50px;
font-weight: 400;
line-height: 59px;
letter-spacing: -0.005em;
color: #757575;
}
.learn-more-tablet {
  font-family: 'Roboto';
font-style: normal;
font-weight: 700;
font-size: 16px;
line-height: 19px;
/* identical to box height */

display: flex;
align-items: center;
letter-spacing: 0.00892857em;
text-transform: uppercase;

color: #005F6C;
}
.travel-quote-tablet{
  font-family: 'Roboto';
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 132.19%;
/* identical to box height, or 24px */

display: flex;
align-items: center;
text-transform: uppercase;

/* white */

color: #FFFFFF;
}
.travel-badge-tablet {
  max-width:  140px;
  object-fit: scale-down;
  margin-right: -3rem;
  z-index: 5;
}
  .title-text-tablet {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 55px;
    line-height: 125.19%;
    /* or 69px */

    letter-spacing: -0.04em;

    /* Gray/700 */

  color: #616161;
  }

.title-text-small {
  font-family: 'Roboto';
font-style: normal;
font-weight: 400;
font-size: 60px;
line-height: 132.19%;
/* or 79px */
color: #616161;
  }

.title-text-large {
  font-size: 60px;
  font-weight: 500;
  line-height: 132.19%;
  color: #616161;
  margin-top: 6rem;
  }

  .section1{
  position: relative;
  width: 100%;
  height: 700px;
  background: url("../../../../public/img/travel-us-background.jpg") no-repeat;
  background-size: cover;
}

.section1-tablet{
  position: relative;
  width: 100%;
  height: 100vh;
  background: url("/img/travel-us-background-tablet.png") no-repeat;
  background-size: left;
}

.section2{
  background-color: #EFF6F8;
}

.large {
    height: 900px;
}
.travel-qt {
  max-width:  120px;
  object-fit: scale-down;
  margin-right: -4rem;
  z-index: 5;
}

.learn {
  border: 1px solid #00A1B7 !important;
  color: #00A1B7 !important;
}

.travel-text {
font-style: normal;
font-weight: normal;
font-size: 60px;
line-height: 70px;
text-align: center;
letter-spacing: -0.005em;
color: #757575;
background-color: white;
}

.zoom {
    transition: transform .2s;
}

.zoom:hover {
    transform: scale(1.2);
}

.product-text {
  font-family: 'Roboto';
font-style: normal;
font-weight: 300;
font-size: 36px;
line-height: 42px;
text-align: center;
letter-spacing: -0.005em;
text-transform: uppercase;

/* Gray/600 */

color: #757575;
}

</style>
